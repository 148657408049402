import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isExpiry } from "../_helpers/isExpiry";
import ErrorBoundary from "../components/ErrorBoundary/ErrorBoundary";
import {config} from "../_config";
const {applicationTypeId} = config
export const PublicRoute = ({ component: Component, ...rest }) => {
  // if(applicationTypeId==24 || applicationTypeId==32){
  // return <Route render={props=><Redirect
  //   to={{
  //     pathname: "/continueoption-tvm"
  //   }}
  // />} />
  // }
    return <Route
      {...rest}
      render={props =>
        !isExpiry() ? (
          <ErrorBoundary {...props}>
          <Component {...props} />
          </ErrorBoundary>
        ) : (
          <Redirect
            to={{
              pathname: "/Scanbutton"
            }}
          />
        )
      }
    />
};
